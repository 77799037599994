
    import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
    import ReservationPayload from "@/store/modules/reservation/models";
    import { PaxValidationErrorValue } from "@/models/reservation/paxValidationError";
    import DatePicker from "@/app/common/components/DatePicker.vue";
    import { ReservationRoomPaxModel } from "@/apiManager/reservation/reservationData";
    import { LoginTypeEnum } from "@/apiManager/login/loginData";
    import { PaxFormEnum } from "@/apiManager/_common/enums";

    @Component
    export default class PaxForm extends Vue
    {
        @Prop({ type: Number, required: true }) private readonly indexRoom!: number;
        @Prop({ type: Number, required: true }) private readonly indexPax!: number;
        @Prop({ type: Object, required: true }) private readonly paxValidationError!: PaxValidationErrorValue;

        get components()
        {
            let components = {
                "date-picker": DatePicker
            };

            return components;
        }

        get getPaxGenderList() : {value: string, label: string}[]
        {
            return [
                {value: "", label: ""},
                {value: "F", label: this.i18n(this.getRezToursLanguageEnum.sexF)},
                {value: "M", label: this.i18n(this.getRezToursLanguageEnum.sexM)}
            ];
        }

        get getPaxDisabled(): boolean
        {
            return (this.indexRoom === 0) && (this.indexPax === 0) && (this.$tStore.state.authenticationModal.isAuth) && (this.$tStore.state.authenticationModal.authType === LoginTypeEnum.Pax);
        }

        get getPaxFormEnum(): typeof PaxFormEnum
        {
            return PaxFormEnum;
        }

        get getPax(): ReservationRoomPaxModel
        {
            return this.$tStore.state.reservationState.detail.listRoom[this.indexRoom].listPax[this.indexPax];
        }

        get getFirstPax(): ReservationRoomPaxModel
        {
            return this.$tStore.state.reservationState.detail.listRoom[0].listPax[0];
        }

        get getOptionalAsterisk(): string
        {
            if (this.$tStore.state.authenticationModal.authType === LoginTypeEnum.Agent)
            {
                return "";
            }
            else
            {
                return " *";
            }
        }

        get getSpecialRequestIsVisible(): boolean
        {
            return (this.$tStore.state.authenticationModal.authType === LoginTypeEnum.Agent);
        }

        getFormatPhoneNumber(phoneNumber: string): string
        {
            //Filter only numbers from the input
            let cleaned = (phoneNumber).replace(/\D/g, "");

            //Check if the input is of correct length
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

            if (match)
            {
                return "(" + match[1] + ") " + match[2] + "-" + match[3];
            }

            return phoneNumber;
        }

        getFormatPostalCode(postalCode: string): string
        {
            let cleaned = (postalCode).replace(/(-)|( )/g, "");

            if(cleaned.length===6)
            {
                return cleaned.substr(0, 3).toUpperCase()+" "+cleaned.substr(3).toUpperCase();
            }

            return postalCode;
        }

        get lastName(): string
        {
            return this.getPax.lastName;
        }
        set lastName(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxLastName(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get firstName(): string
        {
            return this.getPax.firstName;
        }
        set firstName(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxFirstName(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get telephone(): string
        {
            return this.getPax.tel1;
        }
        set telephone(value: string)
        {
            let formatPhoneNumber = this.getFormatPhoneNumber(value);

            let payload = new ReservationPayload.Mutations.SetReservationPaxTelephone(
                formatPhoneNumber,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get email(): string
        {
            return this.getPax.email;
        }
        set email(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxEmail(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get address(): string
        {
            return this.getPax.address;
        }
        set address(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxAddress(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get city(): string
        {
            return this.getPax.city;
        }
        set city(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxCity(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get province(): string
        {
            return this.getPax.province;
        }
        set province(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxProvince(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get postal(): string
        {
            return this.getPax.postal;
        }
        set postal(value: string)
        {
            //let formatPostalCode = this.getFormatPostalCode(value);

            let payload = new ReservationPayload.Mutations.SetReservationPaxPostal(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get gender(): string
        {
            return this.getPax.gender;
        }
        set gender(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxGender(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get birthDate(): string
        {
            return this.getPax.birthDate;
        }
        set birthDate(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxBirthdate(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get allergies(): string
        {
            return this.getPax.allergies;
        }
        set allergies(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxAllergies(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get specialRequest(): string
        {
            return this.getPax.specialRequest;
        }
        set specialRequest(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxSpecialRequest(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get telephoneWork(): string
        {
            return this.getPax.tel2;
        }
        set telephoneWork(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxTelephoneWork(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get telephoneWorkExt(): string
        {
            return this.getPax.telExt;
        }
        set telephoneWorkExt(value: string)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxTelephoneWorkExt(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }

        get useSameAddress(): boolean
        {
            return this.getPax.useSameAddress;
        }
        set useSameAddress(value: boolean)
        {
            let payload = new ReservationPayload.Mutations.SetReservationPaxSameAddress(
                value,
                this.indexRoom,
                this.indexPax
            );
            this.$tStore.commit(payload);
        }
    }
