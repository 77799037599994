
    import { Component, Vue, Mixins } from "vue-property-decorator";
    import { Route, RawLocation } from "vue-router";
    import BeforeRouteEnterMixin from "@/mixins/beforeRouteEnterMixin";
    import moment from "moment";
    import PaxValidationError, { PaxValidationErrorValue } from "@/models/reservation/paxValidationError";
    import PaxForm from "@/app/reservation/components/PaxForm.vue";
    import ReservationPayload from "@/store/modules/reservation/models";
    import RoutesEnum from "@/router/routesEnum";
    import ModalInformativePayload from "@/store/modules/modalInformative/models";
    import { AxiosError } from "axios";
    import { ReservationRoomModel } from "@/apiManager/reservation/reservationData";
    import { PaxFormEnum } from "@/apiManager/_common/enums";
    import { LoginTypeEnum } from "@/apiManager/login/loginData";

    //#region validateQuery
    let validateQuery = (p_Query: { [key: string]: string | (string | null)[] }): boolean =>
    {
        if (!p_Query.code)
        {
            return false;
        }
        if (p_Query.code.length === 0)
        {
            return false;
        }

        return true;
    };
    //#endregion

    @Component
    export default class PaxInfo extends Mixins(BeforeRouteEnterMixin)
    {
        get components()
        {
            let components =
                {
                    "pax-form": PaxForm
                };

            return components;
        }

        private reservCode: string = "";
        private msgErrorValidation: PaxValidationError = new PaxValidationError();
        private paxTitleList: string[] = [];

        get getRoomList(): ReservationRoomModel[]
        {
            return this.$tStore.state.reservationState.detail.listRoom;
        }

        msgErrorValidationByRoomPax(p_RoomIndex: number, p_PaxIndex: number): PaxValidationErrorValue
        {
            if (this.msgErrorValidation.roomIndex[p_RoomIndex] && this.msgErrorValidation.roomIndex[p_RoomIndex].paxIndex[p_PaxIndex])
            {
                return this.msgErrorValidation.roomIndex[p_RoomIndex].paxIndex[p_PaxIndex];
            }
            else
            {
                return new PaxValidationErrorValue();
            }
        }

        isValidEmail(eMail: string) : boolean
        {
            // Valid format:
            // any@any.com

            let regexEmail: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regexEmail.test(eMail);
        }

        isValidPhoneNumber(phoneNumber: string) : boolean
        {
            // Valid format:
            // (123) 456-7890
            // +(123) 456-7890
            // +(123)-456-7890
            // +(123) - 456-7890
            // +(123) - 456-78-90
            // 123-456-7890
            // 123.456.7890
            // 1234567890
            // +31636363634
            // 075-63546725

            let regexPhoneNumber: RegExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
            return regexPhoneNumber.test(phoneNumber);
        }

        isValidPostalCode(postalCode: string) : boolean
        {
            // Valid format:
            // h2t-1b8
            // h2z 1b8
            // H2Z1B8

            let regexPhoneNumber: RegExp = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
            return regexPhoneNumber.test(postalCode);
        }

        validePaxInfo(): boolean
        {

            let errorPassengers : PaxValidationError = new PaxValidationError();
            let hasValidationError: boolean = false;

            this.getRoomList.forEach((room, roomIndex) =>
            {
                room.listPax.forEach((pax, paxIndex) =>
                {
                    if(pax.lastName.trim().length === 0)
                    {
                        errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.LastName, this.i18n(this.getRezToursLanguageEnum.lastName) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                        hasValidationError = true;
                    }

                    if(pax.firstName.trim().length === 0)
                    {
                        errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.FirstName, this.i18n(this.getRezToursLanguageEnum.firstName) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                        hasValidationError = true;
                    }

                    if (pax.birthDate.length === 0)
                    {
                        errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Birthdate, this.i18n(this.getRezToursLanguageEnum.dateOfBirth) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                        hasValidationError = true;
                    }
                    else if (!moment(pax.birthDate).isValid() || moment(pax.birthDate).isAfter(moment()) || moment(pax.birthDate).isBefore("1900-01-01"))
                    {
                        errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Birthdate, this.i18n(this.getRezToursLanguageEnum.dateOfBirth) + " " + this.i18n(this.getRezToursLanguageEnum.isInvalid));
                        hasValidationError = true;
                    }

                    if (!pax.useSameAddress)
                    {
                        if(pax.tel1.trim().length === 0 || !this.isValidPhoneNumber(pax.tel1))
                        {
                            if (pax.tel1.trim().length === 0)
                            {
                                errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Telephone, this.i18n(this.getRezToursLanguageEnum.telephone) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                            }
                            else
                            {
                                errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Telephone, this.i18n(this.getRezToursLanguageEnum.telephone) + " " + this.i18n(this.getRezToursLanguageEnum.isInvalid));
                            }
                            hasValidationError = true;
                        }

                        if (pax.email.trim().length === 0 || !this.isValidEmail(pax.email))
                        {
                            if(pax.email.trim().length === 0)
                            {
                                errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Email, this.i18n(this.getRezToursLanguageEnum.email) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                            }
                            else
                            {
                                errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Email, this.i18n(this.getRezToursLanguageEnum.lastName) + " " + this.i18n(this.getRezToursLanguageEnum.isInvalid));
                            }
                            hasValidationError = true;
                        }

                        if (this.$tStore.state.authenticationModal.authType !== LoginTypeEnum.Agent)
                        {
                            if (pax.address.trim().length === 0)
                            {
                                errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Address, this.i18n(this.getRezToursLanguageEnum.address) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                                hasValidationError = true;
                            }

                            if (pax.city.trim().length === 0)
                            {
                                errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.City, this.i18n(this.getRezToursLanguageEnum.city) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                                hasValidationError = true;
                            }

                            if (pax.province.trim().length === 0)
                            {
                                errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Province, this.i18n(this.getRezToursLanguageEnum.province) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                                hasValidationError = true;
                            }

                            // if (pax.postal.trim().length === 0 || !this.isValidPostalCode(pax.postal))
                            // {
                            //     if (pax.postal.trim().length === 0)
                            //     {
                            //         errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Postal, this.i18n(this.getRezToursLanguageEnum.postal) + " " + this.i18n(this.getRezToursLanguageEnum.isRequired));
                            //     }
                            //     else
                            //     {
                            //         errorPassengers.add(roomIndex, paxIndex, PaxFormEnum.Postal, this.i18n(this.getRezToursLanguageEnum.postal) + " " + this.i18n(this.getRezToursLanguageEnum.isInvalid));
                            //     }
                            //     hasValidationError = true;
                            // }
                        }
                    }
                });
            });

            this.msgErrorValidation = errorPassengers;
            return !hasValidationError;
        }

        beforeRouteEnter(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            if (validateQuery(p_To.query))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        beforeRouteUpdate(p_To: Route, p_From: Route, p_Next: (to?: RawLocation | false) => void): any
        {
            if (validateQuery(p_To.query))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        onClickBack()
        {
            this.$router.replace({
                name: RoutesEnum.TravelBag,
                query:
                    {
                        code: this.$tStore.state.reservationState.detail.code
                    }
            });
        }

        async beforeMount()
        {
            this.reservCode = this.$route.query.code as string || "";

            try
            {
                this.paxTitleList.unshift("");
            }
            catch
            {
            }
        }

        onCancelClick()
        {
            this.$tStore.commit(new ReservationPayload.Mutations.SetShowModalCancelReservation(true));
        }

        async onPaymentClick()
        {

            if (this.validePaxInfo())
            {
                //Set address from first contect and set language from current language
                let firstPax = this.getRoomList[0].listPax[0];
                this.getRoomList.forEach((room, indexRoom) =>
                {
                    room.listPax.forEach((pax, indexPax) =>
                    {
                        if ( !(indexRoom === 0 && indexPax === 0) )
                        {
                            /*
                            if (pax.useSameAddress)
                            {
                                let payloadTelephone = new ReservationPayload.Mutations.SetReservationPaxTelephone(
                                    firstPax.telephone,
                                    indexRoom,
                                    indexPax
                                );
                                this.$tStore.commit(payloadTelephone);

                                let payloadEmail = new ReservationPayload.Mutations.SetReservationPaxEmail(
                                    firstPax.email,
                                    indexRoom,
                                    indexPax
                                );
                                this.$tStore.commit(payloadEmail);

                                let payloadAddress = new ReservationPayload.Mutations.SetReservationPaxAddress(
                                    firstPax.address,
                                    indexRoom,
                                    indexPax
                                );
                                this.$tStore.commit(payloadAddress);

                                let payloadCity = new ReservationPayload.Mutations.SetReservationPaxCity(
                                    firstPax.city,
                                    indexRoom,
                                    indexPax
                                );
                                this.$tStore.commit(payloadCity);

                                let payloadProvince = new ReservationPayload.Mutations.SetReservationPaxProvince(
                                    firstPax.province,
                                    indexRoom,
                                    indexPax
                                );
                                this.$tStore.commit(payloadProvince);

                                let payloadPostal = new ReservationPayload.Mutations.SetReservationPaxPostal(
                                    firstPax.postal,
                                    indexRoom,
                                    indexPax
                                );
                                this.$tStore.commit(payloadPostal);
                            }
                            */
                        }

                        let payloadLanguage = new ReservationPayload.Mutations.SetReservationPaxLanguage(
                            this.g_Language,
                            indexRoom,
                            indexPax
                        );
                        this.$tStore.commit(payloadLanguage);
                    });
                });

                try
                {
                    let payload = new ReservationPayload.Actions.SetPassengerReservation();
                    try
                    {
                        let response = await this.$tStore.dispatch(payload);
                    }
                    catch(ex)
                    {
                        if ((ex as AxiosError)?.response?.status === 401 && this.$tStore.state.configState.setting.license.authIsRequired)
                        {
                            return;
                        }

                        let payloadModal = new ModalInformativePayload.Mutations.DisplayModalInformative(
                            this.i18n(this.getRezToursLanguageEnum.serverErrorTitle),
                            this.i18n(this.getRezToursLanguageEnum.serverErrorMsg)
                        );

                        this.$tStore.commit(payloadModal);
                        return;
                    }

                    this.$router.replace(
                        {
                            name: RoutesEnum.Payment,
                            query:
                                {
                                    code: this.$tStore.state.reservationState.detail.code
                                }
                        }
                    );
                }
                catch
                {

                }

            }
        }
    }
