import { PaxFormEnum } from "@/apiManager/_common/enums";

class PaxValidationError
{
    roomIndex : { [id: number] : PaxValidationErrorRoom; } = {};
    add(p_RoomIndex: number, p_PaxIndex: number, p_PaxFormEnum: PaxFormEnum, p_Value: string)
    {
        if (!this.roomIndex[p_RoomIndex])
        {
            this.roomIndex[p_RoomIndex] = new PaxValidationErrorRoom();
        }

        if (!this.roomIndex[p_RoomIndex].paxIndex[p_PaxIndex])
        {
            this.roomIndex[p_RoomIndex].paxIndex[p_PaxIndex] = new PaxValidationErrorValue();
        }

        this.roomIndex[p_RoomIndex].paxIndex[p_PaxIndex].value[p_PaxFormEnum] = p_Value;
    }
}

export class PaxValidationErrorRoom
{
    paxIndex : { [id: number] : PaxValidationErrorValue; } = {};
}

export class PaxValidationErrorValue
{
    value : { [id in PaxFormEnum]? : string; } = {};
}

export default PaxValidationError;